import React, { useState } from "react";
import styled from "styled-components";
import { parseAmplifyError } from "utils/errors";
import { Analytics } from "aws-amplify";

import {
  FormLayout,
  TextField,
  Button,
  Layout,
  Card,
  Stack,
  Form,
  DisplayText,
  TextStyle,
  Link,
  Banner,
  InlineError,
} from "@shopify/polaris";
import { useUser } from "hooks/useUser";
import { navigate } from "@reach/router";
import rdmLogo from "images/rdm-logo.png";
import Helmet from "react-helmet";
import { Logger } from "aws-amplify";

function Login() {
  const logger = new Logger("Login");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [shouldChangePassword, setShouldChangePassword] = useState(false);
  const [tmpUser, setTmpUser] = useState();
  const [newPassword, setNewPassword] = useState();
  const { user, login, completeLogin, updateUser } = useUser();

  if (user) {
    navigate("/dashboard");
    return null;
  }
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    if (!email || !password) {
      return;
    }
    setIsSubmitting(true);
    try {
      const user = await login({ email: email.toLowerCase(), password });
      console.log(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsSubmitting(false);
        setTmpUser(user);
        setLoginError("");
        setShouldChangePassword(true);
      } else {
        //FIXME: move logic into useUser
        const isReseller =
          user.signInUserSession.idToken.payload["cognito:groups"] &&
          user.signInUserSession.idToken.payload["cognito:groups"].includes(
            "Reseller"
          );
        if (isReseller) {
          Analytics.record({
            name: "login",
            attributes: { email },
          });
        }
        updateUser();
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      logger.error(err);
      Sentry.captureException(parseAmplifyError(err));
      setLoginError(parseAmplifyError(err));
      setIsSubmitting(false);
    }
  }
  async function handleChangePassword(e) {
    e.preventDefault();
    if (!newPassword) {
      setNewPasswordError(true);
    }
    try {
      setIsSubmitting(true);
      await completeLogin({ user: tmpUser, newPassword });
      updateUser();
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      logger.error(err);
      setIsSubmitting(false);
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>Login | Reserved area</title>
      </Helmet>
      <Container>
        {!shouldChangePassword ? (
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Logo>
                <Image src={rdmLogo} alt="Rue des Mille logo" />
              </Logo>
              <DisplayText size="large">Log in</DisplayText>
              <TextStyle variation="subdued">
                Continue to Rue des Mille reserved area
              </TextStyle>
              <TextField
                label="Email"
                onChange={value => {
                  setLoginError("");
                  setEmailError(false);
                  setEmail(value);
                }}
                value={email}
                type="email"
                autoFocus
                id="login-email"
              />
              {emailError && (
                <InlineError
                  message="La mail non è valida"
                  fieldID="login-email"
                />
              )}
              <TextField
                label="Password"
                onChange={value => {
                  setLoginError("");
                  setPasswordError(false);
                  setPassword(value);
                }}
                value={password}
                type="password"
                id="login-password"
              />
              {passwordError && (
                <InlineError
                  message="La password non è valida"
                  fieldID="login-password"
                />
              )}
              <Stack alignment="baseline">
                <Button primary loading={isSubmitting} submit>
                  Log in
                </Button>
                <Link url="/forgot-password" monochrome>
                  Forgot password?
                </Link>
              </Stack>
              {loginError && <Banner title={loginError} status="critical" />}
            </FormLayout>
          </Form>
        ) : (
          <Form onSubmit={handleChangePassword}>
            <FormLayout>
              <Logo>
                <Image src={rdmLogo} alt="Rue des Mille logo" />
              </Logo>
              <DisplayText size="large">Change password</DisplayText>
              <TextStyle variation="subdued">Insert new password</TextStyle>
              <TextField
                label="New password"
                onChange={value => {
                  setNewPasswordError(false);
                  setNewPassword(value);
                }}
                value={newPassword}
                type="password"
                id="login-new-password"
              />
              {newPasswordError && (
                <InlineError
                  message="La nuova password non è valida"
                  fieldID="login-new-password"
                />
              )}
              <Stack alignment="baseline">
                <Button primary loading={isSubmitting} submit>
                  Log in
                </Button>
              </Stack>
            </FormLayout>
          </Form>
        )}
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .Polaris-FormLayout {
    width: 400px;
    @media (max-width: 500px) {
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
    }
  }
`;

const Logo = styled.div`
  width: 200px;
`;

const Image = styled.img`
  width: 100%;
`;

export default Login;
